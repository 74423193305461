import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import debounce from "lodash.debounce";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";
import classnames from "classnames";

import Loader from "../../common/Loader";
import { Pagination } from "../../";
import { StatusButton } from "../../";
import _ from "../../../i18n";
import {
  getClients,
  deleteClient,
  emailReminder,
  associateDelegationToFolders,
} from "../../../api";
import { LEGAL_FORM } from "../../../config";
import { formatPhone, formatHidePhone, obscureEmail } from "../../../utils";
import styles from "./Client.module.scss";
import ModalDelegationType from "./ModalDelegationType";
import ModalDelegation from "./ModalDelegation";
import ModalContact from "./ModalContact";

const ClientList = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const language = useSelector((state) => state.params.language);
  const [sort, setSort] = useState({
    property: "name",
    dir: "asc",
  });
  const [dropDownId, setDropDownId] = useState(null);
  const [search, setSearch] = useState("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSending, setIsSending] = useState(false);
  const [openDelegationTypeModal, setOpenDelegationTypeModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openDelegationModal, setOpenDelegationModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hasDelegationItems, setHasDelegationItems] = useState([]);
  const { token, navCommunity } = auth;
  const LIMIT = 10;

  let { isLoading, data, refetch } = useQuery(
    ["listClients", token, sort, search, currentPage],
    async () => {
      if (token) {
        try {
          const response = await getClients({
            token,
            search,
            limit: LIMIT,
            page: currentPage,
            sort,
            organizationId: fiduciary.id,
          });
          setHasDelegationItems(
            response.data.data
              .filter((item) => item.delegations.length > 0)
              .map((item) => item.id)
          );
          return response.data;
        } catch (e) {
          return null;
        }
      }
    }
  );

  useEffect(() => {
    if (props.refreshList) {
      queryClient.invalidateQueries("listClients");
      props.setRefreshList(false);
    }
  }, [props.refreshList]);

  const handleSearchChange = (e) => {
    debouncedLoadResults(e.target.value);
  };

  const debouncedLoadResults = debounce((search) => {
    setSearch(search);
  }, 1000);

  const toggleDropDown = (e, userId) => {
    e.stopPropagation();
    dropDownId === userId ? setDropDownId(null) : setDropDownId(userId);
  };

  const handleDeleteAction = () => {
    if (currentDeleteItem) {
      setDeleting(true);
      deleteClient(auth.token, currentDeleteItem.id)
        .then((resp) => {
          queryClient.invalidateQueries("listClients");
          setTimeout(() => {
            toast.success(_("successfully_deleted"));
            setDeleting(false);
            setIsOpenDeleteModal(false);
            props.afterDelete();
          }, 1000);
        })
        .catch((e) => {
          setDeleting(false);
        });
    }
  };

  const getActions = (item) => {
    return item.adminisId ? (
      <div className={styles.lockIcon}>
        <i className="icon-ttp-locked"></i>
      </div>
    ) : (
      <div className={styles.dropDownContainer}>
        <span
          className={`${styles.cellIcon} ${
            dropDownId === item.id && styles.active
          }`}
        >
          <span
            className={`${
              dropDownId === item.id ? "icon-ttp-close" : "icon-ttp-three-dots"
            } ${styles.settingsIcon}`}
            onClick={(e) => toggleDropDown(e, item.id)}
          />
        </span>
        <ul
          className={`${styles.menuDropdown} ${
            dropDownId === item.id && styles.show
          }`}
        >
          {item.uaClientStatus !== "PENDING" && (
            <li
              onClick={(e) => {
                toggleDropDown(e, item.id);
                props.setCurrentItem(item);
                props.setSideBar("CLIENT_FORM");
              }}
            >
              {_("edit")}
            </li>
          )}
          <li
            onClick={() => {
              setCurrentDeleteItem(item);
              setIsOpenDeleteModal(true);
            }}
          >
            {_("delete")}
          </li>
        </ul>
      </div>
    );
  };

  const handleClick = (fiduciaryId, userId) => {
    setIsSending(true);
    let data = {
      token,
      language,
      uid: userId,
      fid: fiduciaryId,
      type: "CLIENT",
    };
    emailReminder(data)
      .then(() => {
        toast.success(_("reminder_email_sent_successfully"));
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      })
      .finally(() => setIsSending(false));
  };

  const handleSelectItem = (item) => {
    let hasDelegation = item.delegations.length > 0 ? true : false;
    if (selectedItems.includes(item.id)) {
      setSelectedItems(selectedItems.filter((id) => id !== item.id));
    } else {
      if (selectedItems.length > 0) {
        selectedItems.forEach((id) => {
          if (hasDelegationItems.includes(id)) {
            hasDelegation = true;
          }
        });
        if (!hasDelegation) setSelectedItems([...selectedItems, item.id]);
      } else {
        setSelectedItems([item.id]);
      }
    }
  };

  const refreshAssociation = async () => {
    const currentYear = new Date().getFullYear();
    await associateDelegationToFolders(
      auth.token,
      navCommunity?.id,
      currentYear
    )
      .then((resp) => {
        queryClient.invalidateQueries("listClients");
        setTimeout(() => {
          toast.success(_("successfully_refreshed"));
        }, 1000);
      })
      .catch((e) => {
        console.log("Error");
      });
  };

  return (
    <>
      {isSending && <div className="lmask"></div>}
      <div className={styles.listContainer}>
        <h3>
          {_("client_file_list")}{" "}
          {!isLoading && data?.nbResult && <span>({data.nbResult})</span>}
          <i
            onClick={() => setOpenDelegationTypeModal(true)}
            className={classnames(styles["btDType"], "icon-ttp-settings-alt")}
          ></i>
          {/*refresh must be authorised for deg only*/}
          {/* <i
            className={`icon-ttp-refresh ${styles.refresh}`}
            onClick={() => refreshAssociation()}
          />*/}
        </h3>

        <div className={styles.inputSearch}>
          <span className={styles.inputSearch_icon}>
            <i className="icon-ttp-magnifier"></i>
          </span>
          <input
            type="text"
            placeholder="Recherche ..."
            onChange={(e) => handleSearchChange(e)}
          />
        </div>
      </div>

      {props.page && props.page === "SETTING" && (
        <ul className={styles.tabForms}>
          <li
            onClick={() => {
              props.setCurrentItem(null);
              props.setSideBar("CLIENT_FORM");
            }}
            className={props.sideBar === "CLIENT_FORM" ? styles.active : ""}
          >
            Ajout Manuel
          </li>
          <li
            onClick={() => {
              props.setCurrentItem(null);
              props.setSideBar("CLIENT_IMPORT");
            }}
            className={props.sideBar === "CLIENT_IMPORT" ? styles.active : ""}
          >
            Import (.xls)
          </li>
          {selectedItems.length > 0 && (
            <li>
              <div
                className={styles.delegationAction}
                onClick={() => setOpenDelegationModal(true)}
              >
                {_("delegate")}
              </div>
            </li>
          )}
          {selectedItems.length === 1 && (
            <li>
              <div
                className={styles.delegationAction}
                onClick={() => {
                  if (selectedItems.length === 1) setOpenContactModal(true);
                }}
              >
                {_("manage_contacts")}
              </div>
            </li>
          )}
          {/* <li
            onClick={() => {
              props.setCurrentItem(null);
              props.setSideBar("CLIENT_EXPRESS");
            }}
            className={props.sideBar === "CLIENT_EXPRESS" ? styles.active : ""}
          >
            Ajout Express
          </li> */}
        </ul>
      )}
      <div className={styles.tableContainer}>
        <table className={`${styles.table} unstriped`}>
          <thead>
            <tr>
              <th></th>
              <th>{_("delegations")}</th>
              <th width="30%">
                <span>{_("company_name")}</span>
                <span className={styles.sortBox}>
                  <i
                    className={`icon-ttp-arrow-down ${
                      sort.property === "name" &&
                      sort.dir === "asc" &&
                      styles.iconActive
                    }`}
                    onClick={() => setSort({ property: "name", dir: "asc" })}
                  ></i>
                  <i
                    className={`icon-ttp-arrow-up ${
                      sort.property === "name" &&
                      sort.dir === "desc" &&
                      styles.iconActive
                    }`}
                    onClick={() => setSort({ property: "name", dir: "desc" })}
                  ></i>
                </span>
              </th>
              <th width="30%">
                <span>{_("legal_form")}</span>
                <span className={styles.sortBox}>
                  <i
                    className={`icon-ttp-arrow-down ${
                      sort.property === "legalForm" &&
                      sort.dir === "asc" &&
                      styles.iconActive
                    }`}
                    onClick={() =>
                      setSort({ property: "legalForm", dir: "asc" })
                    }
                  ></i>
                  <i
                    className={`icon-ttp-arrow-up ${
                      sort.property === "legalForm" &&
                      sort.dir === "desc" &&
                      styles.iconActive
                    }`}
                    onClick={() =>
                      setSort({ property: "legalForm", dir: "desc" })
                    }
                  ></i>
                </span>
              </th>
              <th width="10%">{_("language")}</th>
              <th width="30%">
                <span>{_("linked_contact")}</span>
              </th>
              <th>{_("other_contacts")}</th>
              {/* <th></th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={7} className={styles.centerCell}>
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#B2BCC6"}
                  />
                </td>
              </tr>
            ) : data?.data && data.data.length > 0 ? (
              data.data.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className={styles.action}>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.id)}
                        onChange={() => {
                          handleSelectItem(item);
                        }}
                      />
                      {getActions(item)}
                    </div>
                  </td>
                  <td>
                    {item.delegations.length > 0 &&
                      item.delegations.map((d) => (
                        <span className={styles.delegation} key={d.id}>
                          {`${d.delegationType["fr"]}: ${d.user}`}
                        </span>
                      ))}
                  </td>
                  <td>
                    {item.name} <br />
                    {item.uen}
                  </td>
                  <td>
                    {LEGAL_FORM[item.legalForm]
                      ? LEGAL_FORM[item.legalForm]
                      : null}
                  </td>
                  <td>{item.languagePreferences}</td>
                  <td>
                    {`${item.legalRepresentative.lastName} ${item.legalRepresentative.firstName}`}
                    <br />
                    {item.uaClientStatus !== "PENDING"
                      ? item.legalRepresentative.mainEmail
                      : obscureEmail(item.legalRepresentative.mainEmail)}
                    {item.legalRepresentative.mainPhone && (
                      <>
                        <br />
                        {item.uaClientStatus !== "PENDING"
                          ? formatPhone(item.legalRepresentative.mainPhone)
                          : formatHidePhone(item.legalRepresentative.mainPhone)}
                      </>
                    )}
                  </td>
                  <td>
                    {item.contacts?.length > 0 &&
                      item.contacts.map((c, i) => (
                        <span className={styles.delegation} key={`c-${i}`}>
                          {`${c.lastName} ${c.firstName}`}
                        </span>
                      ))}
                  </td>
                  {/* <td>
                  {item.legalRepresentative.mainPhone &&
                    (item.uaClientStatus !== "PENDING"
                      ? formatPhone(item.legalRepresentative.mainPhone)
                      : formatHidePhone(item.legalRepresentative.mainPhone))}
                </td> */}

                  <td>
                    <StatusButton
                      status={item.uaClientStatus}
                      handleClick={() =>
                        handleClick(item.id, item.legalRepresentative.id)
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className={styles.centerCell}>
                  Liste vide (aucun dossier n'a été ajouté)
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data?.nbResult && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.nbResult}
          pageSize={LIMIT}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onConfirm={() => handleDeleteAction()}
        inProcess={deleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      {openDelegationTypeModal && (
        <ModalDelegationType
          closeModal={() => setOpenDelegationTypeModal(false)}
        />
      )}
      {openDelegationModal && (
        <ModalDelegation
          folderIds={selectedItems}
          folders={data?.data}
          refetchClients={refetch}
          closeModal={() => setOpenDelegationModal(false)}
        />
      )}
      {openContactModal && (
        <ModalContact
          folderIds={selectedItems}
          folders={data?.data}
          refetchClients={refetch}
          closeModal={() => setOpenContactModal(false)}
        />
      )}
    </>
  );
};

export default ClientList;
