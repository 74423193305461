import React, { useEffect, useRef, useState } from "react";
import { sortableHandle } from "react-sortable-hoc";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Public from "../Portals/Public";
import Client from "../Portals/Client";
import Collaborator from "../Portals/Collaborator";
import Tabs from "../../../common/Tabs";
import _ from "../../../../i18n";
import styles from "./MyPortals.module.scss";
import {
  getFolderBySubdomain,
  getOrganizationSettings,
  saveCommunityPortalsSettings,
  saveFolderSpecialties,
  setUsedSpecialties,
} from "../../../../api";
import {
  setHubPreferencesPortals,
  setPortalSubDomain,
} from "../../../../store";
import { DEFAULT_APPS, ALLOWED_APPS_OPTIONS } from "../../../../config";

import Button from "../../../common/Button";
import {
  sanitizeSubdomain,
  validateEmail,
  validatePhone,
} from "../../../../utils";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const apps = [
  { name: "oFFFcourse", value: "OFFFCOURSE", icon: "offfcourse.svg" },
  { name: "Blog", value: "BLOG", icon: "blog.svg", type: "BOTH", relevance: 3 },
  //{ name: "E-News", value: "Newsletter", icon: "sending.svg" },
  // { name: "Faq", value: "FAQ", icon: "faq.svg" },
  // { name: "Dashboard", value: "DASHBOARD", icon: "dashboard.svg" },
  // { name: "PowerTeam", value: "POWERTEAM", icon: "powerteam.svg" },
  // { name: "Webtools", value: "WEBTOOLS", icon: "webtools.svg" },
];

export const DragHandle = sortableHandle(() => (
  <span className={styles.dragHandler}>
    <i className="icon-ttp-drag"></i>
  </span>
));

const MyPortals = () => {
  const [fiduciarySettings, setFiduciarySettings] = useState(null);
  const components = {
    collaborator: Collaborator,
    client: Client,
    public: Public,
  };
  const auth = useSelector((state) => state.auth);

  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [isLoading, setIsLoading] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const [subdomainError, setSubdomainError] = useState("");
  const [subdomainSuggestion, setSubdomainSuggestion] = useState("");
  const [organizationSettingsId, setOrganizationSettingsId] = useState(null);
  const [allowedApps, setAllowedApps] = useState([]);
  const [contactEmailError, setContactEmailError] = useState("");
  const [contactPhoneError, setContactPhoneError] = useState("");
  const [appTabs, setAppTabs] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [portals, setPortals] = useState({
    collaborator: {
      enabled: false,
      config: {
        apps: [],
        widgets: [],
      },
    },
    public: {
      enabled: false,
      customDesign: false,
      orderMemberDesign: false,
      orderMemberDesignVersion: "v1",
      config: {
        packs: {
          activated: false,
          list: [],
          featuresCategories: [],
        },
        numbers: {
          activated: false,
          titleFr: "",
          titleEn: "",
          titleNl: "",
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          list: [],
          image: null,
        },
        partners: {
          activated: false,
          titleFr: "",
          titleEn: "",
          titleNl: "",
          list: [],
        },
        memberMedia: {
          activated: false,
          list: [],
        },
        about: {
          activated: false,
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          titleFr: "",
          titleEn: "",
          titleNl: "",
          image: null,
          aboutPage: {
            banner: {
              descriptionFr: "",
              descriptionEn: "",
              descriptionNl: "",
              titleFr: "",
              titleEn: "",
              titleNl: "",
            },
          },
        },
        singleService: {
          activated: false,
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          titleFr: "",
          titleEn: "",
          titleNl: "",
          image: null,
        },
        media: {
          activated: false,
          list: [],
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
        },
        news: {
          titleFr: "",
          descriptionFr: "",
          titleEn: "",
          descriptionEn: "",
          titleNl: "",
          descriptionNl: "",
          activated: false,
        },
        specialty: {
          titleFr: "",
          descriptionFr: "",
          titleEn: "",
          descriptionEn: "",
          titleNl: "",
          descriptionNl: "",
          activated: false,
        },
        team: {
          titleFr: "",
          descriptionFr: "",
          titleEn: "",
          descriptionEn: "",
          titleNl: "",
          descriptionNl: "",
          activated: false,
          list: [],
        },
        services: {
          titleFr: "",
          titleEn: "",
          titleNl: "",
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          activated: false,
          list: [],
        },
        faqs: {
          activated: false,
          titleFr: "",
          titleEn: "",
          titleNl: "",
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          list: [],
        },
        testimonials: {
          activated: false,
          titleFr: "",
          titleEn: "",
          titleNl: "",
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          list: [],
        },
        history: {
          activated: false,
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          titleFr: "",
          titleEn: "",
          titleNl: "",
        },
        values: {
          activated: false,
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          titleFr: "",
          titleEn: "",
          titleNl: "",
          list: [],
          video: null,
        },
        gallery: {
          activated: false,
          descriptionFr: "",
          descriptionEn: "",
          descriptionNl: "",
          titleFr: "",
          titleEn: "",
          titleNl: "",
          list: [],
        },
        contact: {
          activated: false,
          email: "",
          phone: "",
          latitude: "50.8503396",
          longitude: "4.3517103",
          schedule: {
            MON: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            TUE: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            WED: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            TUR: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            FRI: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            SAT: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            SUN: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
          },
        },
      },
    },
    client: {
      enabled: false,
      config: {
        apps: [],
        widgets: [],
      },
    },
  });

  const [portalsDataPublic, setPortalsDataPublic] = useState(null);
  const [portalsDataClient, setPortalsDataClient] = useState(null);
  const [portalsDataCollaborator, setPortalsDataCollaborator] = useState(null);

  const [folderSpecialties, setFolderSpecialties] = useState(null);

  useEffect(() => {
    if (fiduciary) {
      setIsLoading(true);
      let tabAllowedApps = [];
      if (
        fiduciary.uaSettings &&
        fiduciary.uaSettings.allowedApps &&
        fiduciary.uaSettings.allowedApps.length > 0
      ) {
        fiduciary.uaSettings.allowedApps.forEach((i) => {
          const row = ALLOWED_APPS_OPTIONS.find((j) => j.value === i);
          if (row) {
            tabAllowedApps.push(row);
          }
        });
      } else {
        tabAllowedApps = ALLOWED_APPS_OPTIONS.filter(
          (i) => i && DEFAULT_APPS.includes(i.value)
        );
      }
      setAllowedApps(tabAllowedApps);

      let allowedTabs = ["collaborator"];
      if (
        fiduciary.uaSettings &&
        fiduciary.uaSettings.allowedTabs &&
        fiduciary.uaSettings.allowedTabs.length > 0
      ) {
        if (fiduciary.uaSettings.allowedTabs.includes("CLIENT")) {
          allowedTabs.push("client");
        }
      }
      allowedTabs.push("public");
      setAppTabs(allowedTabs);

      getOrganizationSettings(auth.token, fiduciary.id)
        .then((response) => {
          if (response.data.data) {
            setOrganizationSettingsId(response.data.data.id);
            if (
              response.data.data.hubPreferences &&
              response.data.data.hubPreferences.portals
            ) {
              setFiduciarySettings({
                ...response.data.data.hubPreferences.portals,
              });
            }

            let subdomainValue = "";
            if (response.data.data.subdomain) {
              subdomainValue = response.data.data.subdomain;
            } else {
              subdomainValue = sanitizeSubdomain(fiduciary.name);
            }
            setSubdomain(subdomainValue);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
      setIsLoading(false);
    }
  }, [fiduciary]);

  const handleCheckPortal = (key, value) => {
    setPortals({
      ...portals,
      [key]: {
        ...portals[key],
        enabled: value,
      },
    });
  };

  const validateSubdomain = async () => {
    if (subdomain.trim().length === 0) {
      setSubdomainError(_("required_field"));
      setSubdomainSuggestion("");

      return false;
    } else if (sanitizeSubdomain(subdomain) !== subdomain) {
      setSubdomainError(_("invalid_subdomain"));
      setSubdomainSuggestion(sanitizeSubdomain(subdomain));

      return false;
    } else {
      setIsLoading(true);
      try {
        const res = await getFolderBySubdomain({
          token: auth.token,
          subdomain,
        });

        if (
          res.data.data &&
          organizationSettingsId &&
          res.data.data.id !== organizationSettingsId
        ) {
          setSubdomainError(_("subdomain_already_exists"));
          setSubdomainSuggestion("");
          return false;
        } else {
          setSubdomainError("");
          setSubdomainSuggestion("");
        }
      } catch (error) {
        setSubdomainError("");
        setSubdomainSuggestion("");
      }
    }

    return true;
  };

  const renderTab = (name) => {
    const DynamicComponent = components[name];

    return (
      <DynamicComponent
        fiduciarySettings={fiduciarySettings}
        setFiduciarySettings={setFiduciarySettings}
        portals={portals}
        setPortals={setPortals}
        handleCheckPortal={handleCheckPortal}
        apps={allowedApps}
        setIsLoading={setIsLoading}
        folderSpecialties={folderSpecialties}
        setFolderSpecialties={setFolderSpecialties}
        subdomain={subdomain}
        setSubdomain={setSubdomain}
        subdomainError={subdomainError}
        subdomainSuggestion={subdomainSuggestion}
        setSubdomainError={setSubdomainError}
        setSubdomainSuggestion={setSubdomainSuggestion}
        contactEmailError={contactEmailError}
        setContactEmailError={setContactEmailError}
        contactPhoneError={contactPhoneError}
        setContactPhoneError={setContactPhoneError}
        setPortalsData={
          name === "collaborator"
            ? setPortalsDataCollaborator
            : name === "client"
            ? setPortalsDataClient
            : name === "public"
            ? setPortalsDataPublic
            : null
        }
      />
    );
  };

  const saveConfig = async () => {
    const validate = await validateSubdomain();
    if (!validate) {
      setIsLoading(false);
      return null;
    }

    if (
      portalsDataPublic.config.contact.email?.length > 0 &&
      !validateEmail(portalsDataPublic.config.contact.email)
    ) {
      setContactEmailError(_("validate_email"));
      setIsLoading(false);
      return;
    } else {
      setContactEmailError();
    }
    if (
      portalsDataPublic.config.contact.phone?.length > 0 &&
      !validatePhone(portalsDataPublic.config.contact.phone).valid
    ) {
      setContactPhoneError(_("validate_phone"));
      setIsLoading(false);
      return;
    } else {
      setContactPhoneError();
    }

    setIsLoading(true);
    saveCommunityPortalsSettings({
      token: auth.token,
      communityId: fiduciary.id,
      data: {
        portals: {
          public: portalsDataPublic,
          client: portalsDataClient,
          collaborator: portalsDataCollaborator,
        },
        subdomain,
      },
    })
      .then((resp) => {
        const resPortals = JSON.parse(resp.data.data.portals);

        saveFolderSpecialties({
          token: auth.token,
          data: {
            organization: fiduciary.id,
            specialties: folderSpecialties.list,
            favorites: folderSpecialties.favorites,
          },
        }).then((res) => {
          const list =
            res.data.data && res.data.data.specialties
              ? res.data.data.specialties.split(",").map((el) => parseInt(el))
              : [];
          const favorites =
            res.data.data && res.data.data.favorites
              ? res.data.data.favorites.split(",").map((el) => parseInt(el))
              : [];

          setFolderSpecialties({ list, favorites });
        });
        setPortals({
          ...portals,
          public: {
            ...portals.public,
            config: {
              ...portals.public.config,
              team: {
                ...portals.public.config.team,
                list: resPortals.public.config.team.list,
              },
            },
          },
        });

        dispatch(setPortalSubDomain(subdomain));
        dispatch(setHubPreferencesPortals(resPortals));
        setIsLoading(false);
        toast.success(_("successfully_saved"));

        setUsedSpecialties({
          token: auth.token,
          data: {
            list: folderSpecialties.list,
          },
        });
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(_("error"));
      });
  };

  const disableSave = () => {
    const publicPortal = portals.public.config;
    /* if (
      portals.public.enabled &&
      ["v1", "v2"].includes(portals.public.orderMemberDesignVersion) &&
      !publicPortal.contact.activated &&
      !publicPortal.memberMedia.activated &&
      // !publicPortal.media.activated &&
      !publicPortal.news.activated &&
      // !publicPortal.specialty.activated &&
      !publicPortal.team.activated
    ) {
      return true;
    }*/
    return false;
  };

  return (
    <div>
      {isLoading ? <div className="lmask"></div> : null}
      <p className={styles.subtitle}>
        Activez le ou les portails dont vous avez besoin et créez la base de
        votre fondation digitale
      </p>
      <Tabs theme="line-theme">
        {appTabs.map((l, i) => (
          <div key={`apptab${i}`} label={_(l)}>
            {renderTab(l)}
          </div>
        ))}
      </Tabs>
      <div className={styles.actionBar}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div
              className={`cell small-12 medium-12 large-8 large-offset-4 ${styles.actions}`}
            >
              <Button
                variant="default"
                onClick={() => navigate(0)}
                className="m-r-s"
              >
                {_("cancel")}
              </Button>
              <Button disabled={disableSave()} onClick={saveConfig}>
                {_("save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPortals;
